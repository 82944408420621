<template>
    <layout-default :sections="sections" :activeSection="activeSection">
      <section class="bg-light py-1" id="appointment-reservation" data-description="Terminreservierung">
        <div class="container">
          <appointment-scheduling />
        </div>
      </section>
      <section class="bg-white py-1" id="telephone-booking" data-description="Telefonische Buchung">
        <div class="container">
          <telephone-booking />
        </div>
      </section>
      <section class="bg-light py-1" id="whatsapp-booking" data-description="Whatsapp Buchung">
        <div class="container">
          <whatsap-booking />
        </div>
      </section>
      <section class="bg-white py-1" id="online-booking" data-description="Online Buchung">
        <div class="container">
          <online-booking />
        </div>
      </section>
    </layout-default>
</template>

<script>
import { collectSections, observeSections } from '../utils/sectionCollector';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import AppointmentScheduling from '../components/Appointments/AppointmentScheduling.vue';
import TelephoneBooking from '../components/Appointments/TelephoneBooking.vue';
import WhatsapBooking from '../components/Appointments/WhatsapBooking.vue';
import OnlineBooking from '../components/Appointments/OnlineBooking.vue';

export default {
    name: 'Appointments',
    components: {
        LayoutDefault,
        AppointmentScheduling,
        TelephoneBooking,
        WhatsapBooking,
        OnlineBooking
    },
    data() {
        return {
            sections: [],
            activeSection: ''
        };
    },
    methods: {
        updateActiveSection(id) {
            this.activeSection = id;
        }
    },
    mounted() {
        this.sections = collectSections();
        observeSections(this.sections, this.updateActiveSection);
    }
};

</script>
<style>
.text-bold {
  font-weight: bold;
}
</style>
