<template>
  <div>
      <header-with-line header="Terminvereinbarung" class="pb-2" />
    <div class="container text-justify p-2 fs-5">
      <p>
        Um deinen Termin so unkompliziert wie möglich zu gestalten, findest du hier alle Optionen
        – von der telefonischen Buchung bis zur Online-Terminvergabe. Wähle die passende Methode
        für dich und deine Versicherung und erfahre alles Wichtige für deinen reibungslosen Start bei uns.
      </p>
    </div>
    <div class="p-2">
      <div class="row m-auto d-flex justify-content-center align-items-center">
        <div class="col-lg-4 col-md-12 text-center mb-3">
          <img src="../../assets/img/stefanie_pc.jpg" alt="lift" class="img-fluid card-img-appointments"/>
        </div>
        <div class="col-lg-8 col-md-12 fs-5 text-justify">
            <div class="row d-flex justify-content-center">
              <div class="text-center mb-3">
                <img src="../../assets/img/decorations/booking_options_for_therapy_places.svg" class="svg-title img-fluid" />
              </div>
              <p>
                Bitte wähle die für dich passende Option aus. Für detaillierte Informationen,
                wie du bei der Terminbuchung vorgehst, klicke einfach auf die entsprechende
                Methode.
              </p>
            <div class="appointments-container mx-auto">
              <div class="column gesetzlich">
                <div class="header-appointments">Gesetzlich</div>
                <router-link to="/appointments#telephone-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fas fa-phone"></i></div>
                </router-link>
                <router-link to="/appointments#whatsapp-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fab fa-whatsapp"></i></div>
                </router-link>
                <router-link to="/appointments#online-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fas fa-calendar-days"></i></div>
                </router-link>
              </div>
              <div class="column privat">
                <div class="header-appointments">Privat</div>
                <router-link to="/appointments#telephone-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fas fa-phone"></i></div>
                </router-link>
                <router-link to="/appointments#whatsapp-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fab fa-whatsapp"></i></div>
                </router-link>
                <router-link to="/appointments#online-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fas fa-calendar-days"></i></div>
                </router-link>
              </div>
              <div class="column selbstzahler">
                <div class="header-appointments">Selbstzahler</div>
                <router-link to="/appointments#telephone-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fas fa-phone"></i></div>
                </router-link>
                <router-link to="/appointments#whatsapp-booking" exact v-slot="{ navigate, isActive }" custom>
                  <div class="icon" @click="navigate" :class="{ active: isActive }"><i class="p-1 fab fa-whatsapp"></i></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'AppointmentScheduling',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.card-img-appointments {
  border: 3px solid var(--bs-tertiary) !important;
  border-radius: 10px;
  max-height: 500px !important;
}

.card {
  border: 0;
}

.svg-title {
  max-height: 90px;
}

.title-text {
  border: 3px solid var(--bs-tertiary);
  border-radius: 10px;
}

.appointments-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
  background-color: var(--bs-background);
  border-radius: 20px;
  border: var(--bs-tertiary) 3px solid;
  max-width: 500px;
}

.column {
  background-color: var(--bs-background);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

}

.header-appointments {
  background-color: var(--bs-primary);
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
}

.privat .header-appointments {
  background-color: var(--bs-tertiary);
  color: var(--bs-primary)
}

.selbstzahler .header-appointments {
  background-color: white;
  color: var(--bs-primary);
}

.icon {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border: var(--bs-primary) 3px solid;
}

.icon:hover {
  background-color: var(--bs-primary);
  transition: 0.3s;
  cursor: pointer;
  i {
    color: white;
  }
}

.icon i {
  font-size: 24px;
  color: var(--bs-primary);
}

.icon i:hover {
  color: white;
}

@media (max-width: 576px) {
  .header-appointments {
    background-color: var(--bs-primary);
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    width: 100%;
    margin-top: 10px;
  }
  .column {
    background-color: var(--bs-background);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
  }
}
</style>
