<template>
  <div>
    <header-with-line header="WhatsApp-Buchung" class="mb-4" />
    <div class="row m-auto d-flex justify-content-center align-items-center mb-3">
      <div class="col-lg-6 col-md-12 text-center">
        <img src="../../assets/img/whatsapp-phone.png" alt="lift" class="img-fluid card-img-telephone"/>
      </div>
      <div class="col-lg-6 col-md-12 fs-5 text-justify">
        <div class="row">
          <div>
            <span class="text-bold">Bequem und unkompliziert:</span> Vereinbare deinen Termin einfach per WhatsApp
          </div>
          <div>
            <ul class="custom-list-whatsapp">
              <li>
                <img src="../../assets/img/decorations/check.png" alt="plus icon">
                <span>
                  Über WhatsApp kannst du uns <span class="text-bold">ein Foto</span> deiner Heilmittelverordnung senden, damit wir deine Anfrage direkt prüfen können.
                </span>
              </li>
              <li>
                <img src="../../assets/img/decorations/check.png" alt="plus icon">
                <span>
                  Für <span class="text-bold">Privatpatient*innen</span> ist es wichtig, dass wir vorab wissen, für welches unserer Pakete du dich entscheidest. Anschließend senden wir dir die <span class="text-bold">passende Honorar-vereinbarung zur Unterschrift</span> zu, damit wir deinen Termin verbindlich buchen können. Selbstzahler erhalten die Honorarvereinbarung ebenfalls vorab zur Bestätigung
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 fs-5 text-center text-justify whatsapp-box p-4">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-lg-8 col-md-12 fs-5 text-justify">
            <p>
              Unsere Praxisnummer ist auch unsere WhatsApp-Nummer. Wenn du sie bereits gespeichert hast, kannst du uns direkt per WhatsApp anschreiben.
            </p>
          </div>
          <div class="col-lg-4 col-md-12 fs-5 text-center">
            <div class="text-bold h2">09871 657 98 01</div>
            <a href="https://wa.me/4998716579801" target="_blank" rel="noopener noreferrer">
              <div class="icon-telephone h2 mt-3"><i class="p-1 fab fa-whatsapp"></i></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'WhatsapBooking',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.img-card {
  border: 3px solid var(--bs-tertiary) !important;
  border-radius: 10px;
  max-height: 300px !important;
}

.card {
  border: 0;
}

.title-text {
  border: 3px solid var(--bs-tertiary);
  border-radius: 10px;
}

.whatsapp-box {
  border: 3px solid var(--bs-tertiary);
  border-radius: 10px;
  max-height: 300px;
}

.custom-list-whatsapp {
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-list-whatsapp li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list-whatsapp img {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px;
}
</style>
