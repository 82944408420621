<template>
  <div>
    <header-with-line header="Online-Terminbuchung" class="mb-4" />
    <div class="row m-auto d-flex justify-content-center align-items-center mb-3">
      <div class="col-lg-4 col-md-12 text-center">
        <img src="../../assets/img/online-booking.png" class="img-fluid img-card-online"/>
      </div>
    <div class="col-lg-8 col-md-12 fs-5 text-justify">
      <div class="row">
        <div class="text-justify">
          <p>
            Nutze unsere Online-Terminbuchung und vereinbare deinen Termin in nur wenigen Schritten!
          </p>
        </div>
        <div>
          <ul class="custom-list-online">
            <li>
              <img src="../../assets/img/decorations/check.png" alt="plus icon">
              <span>
                <p>
                  Die Online-Terminbuchung ist derzeit für <span class="text-bold">gesetzlich</span> und <span class="text-bold">privat</span> Versicherte in den Bereichen <span class="text-bold">Physiotherapie</span> und <span class="text-bold">Ergotherapie</span> verfügbar.
                </p>
              </span>
            </li>
            <li>
              <img src="../../assets/img/decorations/check.png" alt="plus icon">
              <span>
                <p>
                  <span class="text-bold">Achtung für Heilpraktiker-Termine:</span> „Online-Termine für Heilpraktiker*innen-Leistungen stehen derzeit leider nicht zur Verfügung. Vereinbare in diesem Fall deinen Termin bitte telefonisch oder per WhatsApp.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 fs-5 text-center text-justify whatsapp-box p-4">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-8 col-md-12 fs-5 text-justify">
          <p>
            Sichere dir deinen Termin ganz bequem online! Einfach klicken und deinen Wunschtermin in wenigen Schritten buchen. Wir freuen uns darauf, dich bald bei uns zu begrüßen!
          </p>
        </div>
        <div class="col-lg-4 col-md-12 fs-5 text-center">
          <a href="https://otr.md-therapie.de/6cRlDHAQQN%2BtqdnQvahdeoXRqhTLEl5F" target="_blank" style="text-decoration: none;">
          <div class="icon-telephone h2 mt-3"><i class="p-1 fas fa-calendar-days"></i></div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <header-with-line header="Mit wenigen Klicks zum Wunschtermin" class="mb-4" />
  <div class="row m-auto d-flex justify-content-center align-items-center mb-3">
    <div class="col-lg-7 col-md-12 fs-5 text-justify">
      <div class="img-container-telephone mx-auto">
        <img class="img-fluid mb-3" src="../../assets/img/decorations/its_that_easy.svg" alt="Fleckdekoration">
      </div>
      <div class="text-primary">
        <p>
          Wir zeigen dir wie leicht es ist, online einen Termin bei uns zu reservieren. Egal ob für Physiotherapie oder Ergotherapie – du kannst einfach deine gewünschte Zeit auswählen und die Anfrage abschicken. So kannst du dir deinen Termin ganz bequem sichern. Probier’s aus und erlebe, wie unkompliziert die Buchung bei uns ist!
        </p>
      </div>
    </div>
    <div class="col-lg-5 col-md-12 text-center">
      <div class="video-container">
        <iframe
          src="https://www.youtube.com/embed/sD4cg1WvjFg?autoplay=0&disablekb=1&loop=1&mute=0&modestbranding=1&color=white&iv_load_policy=3&playlist=sD4cg1WvjFg"
        >
        </iframe>
      </div>
    </div>
  </div>
    <div class="col-12 fs-5 text-justify text-primary">
      <p>
        <span class="text-bold">Bitte beachte:</span> Mit deiner Anfrage ist der Termin noch nicht fest gebucht. Die endgültige Bestätigung erhältst du per E-Mail innerhalb von <span class="text-bold">ca. 48 Stunden</span> (werktags). Sollte keine Bestätigung eingehen, wurde dein Termin noch nicht vollständig reserviert – in diesem Fall melde dich gerne bei uns! Termine können online storniert werden, solange die Buchungsbestätigung noch nicht eingegangen ist.
      </p>
    </div>
    <div class="col-12 fs-5 text-justify text-center text-bold text-primary">
      <p>
        Nach Erhalt der Bestätigung ist eine Absage nur noch vor Ort, telefonisch oder per WhatsApp möglich
      </p>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'OnlineBooking',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.img-card-online {
  border: 3px solid var(--bs-tertiary) !important;
  border-radius: 10px;
}

.card {
  border: 0;
}

.title-text {
  border: 3px solid var(--bs-tertiary);
  border-radius: 10px;
}

.video-container {
    position: relative;
    padding-bottom: 75.09%;
    padding-top: 0px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.custom-list-online {
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-list-online li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list-online img {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px;
}
</style>
