<template>
  <div>
      <header-with-line header="Telefonische Buchung" class="pb-2" />
    <div class="py-2">
      <div class="row m-auto d-flex justify-content-center align-items-center mb-3">
        <div class="col-lg-4 col-md-12 text-center">
          <img src="../../assets/img/carolin_tel_square.png" alt="lift" class="img-fluid card-img-telephone"/>
        </div>
        <div class="col-lg-8 col-md-12 fs-5 text-justify">
            <div class="row">
            <p>
              Du bevorzugst die persönliche Buchung am Telefon? Kein Problem – unser Team ist gerne für dich da und berät dich zu freien Terminen.
            </p>
            <ul class="custom-list">
              <li>
                <img src="../../assets/img/decorations/check.png" alt="plus icon">
                <span>
                  Halte bitte deine <span class="text-bold">Heilmittel-verordnung bereit</span>,
                  falls du gesetzlich
                  versichert bist. Bei Privat- und Heilpraktiker-Terminen erstellen wir vorab
                  eine Honorarvereinbarung
                </span>
              </li>
              <li>
                <img src="../../assets/img/decorations/check.png" alt="plus icon">
                <span>
                  Wenn du das erste Mal zu uns kommst, bringe die Verordnung bitte mit oder <span class="text-bold">sende
                  sie vorab</span> per WhatsApp oder E-Mail
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row m-auto d-flex justify-content-center align-items-center">
        <div class="col-lg-7 col-md-12 fs-5">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="img-container-telephone mx-auto">
              <img class="img-fluid mb-3" src="../../assets/img/decorations/tell_us_this_information_over_the_phone.svg" alt="Fleckdekoration">
            </div>
            <ul class="custom-list h4">
              <li>
                <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <span>
                  Heilmittelart
                </span>
              </li>
              <li>
                <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <span>
                  Diagnose
                </span>
              </li>
              <li>
                <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <span>
                  Heilmittel & Anzahl
                </span>
              </li>
              <li>
                <img src="../../assets/img/decorations/plus.svg" alt="plus icon">
                <span>
                  evt. Ergänzendes Heilmittel
                </span>
              </li>
            </ul>
            <div class="col-lg-9 col-md-12">
              <div class="text-center telephone-box mb-3">
                <div class="fs-3 mb-2">
                  Du erreicht uns telefonisch unter:
                </div>
                <div class="text-bold h2">09871 657 98 01</div>
                <a href="tel:098716579801">
                  <div class="icon-telephone h2 mt-3">
                    <i class="p-1 fas fa-phone"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 text-center">
          <img src="../../assets/img/Muster_13-1.gif" class="img-fluid card-img-rezept" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'AppointmentScheduling',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.card-img-telephone {
  border: 3px solid var(--bs-tertiary) !important;
  border-radius: 10px;
}

.card-img-rezept {
  border: 3px solid var(--bs-tertiary) !important;
  border-radius: 10px;
  min-width: 450px;
}

@media (max-width: 768px) {
  .card-img-rezept {
    min-width: 320px;
  }

}

.card {
  border: 0;
}

.title-text {
  border: 3px solid var(--bs-tertiary);
  border-radius: 10px;
}

.custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.custom-list img {
    height: 40px;
    margin-right: 10px;
}

.img-container-telephone {
    max-width: 500px;
}

.telephone-box {
    border: var(--bs-tertiary) 3px solid;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.icon-telephone {
    color: var(--bs-text);
    background-color: var(--bs-background);
    border: var(--bs-text) 3px solid;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
}

.icon-telephone:hover {
    background-color: white;
    transition: 0.3s;
}
</style>
